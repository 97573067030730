<template>
  <div class="login">
    <div class="login-main">
      <div class="h" style="height: 20%;">
        <div class="logo">
          <img src="/static/img/logo.png" style="width: 75%;" />
        </div>
        <div class="h sa-c">
          <div style="font-size: 22px; font-weight: bold;">登录</div>
          <div style="width: 1px; margin: 0 20px; background-color: #e2e2e2; transform: skew(-15deg);"></div>
          <div style="font-size: 22px; font-weight: lighter; color: #c0c4cc;">Login</div>
        </div>
      </div>
      <div style="width: 42%; padding: 10px 0 10px 20px;">
        <div style="color: #606266; padding: 20px 0;">您好！欢迎使用「经销商管理系统（Distributor Management System）」，请先进行登录：</div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px" class="v s" style="width: 100%;">
          <div class="login-form-title h c fc-g">
            <i class="fa fa-user-circle fs-large"></i>&nbsp;账号
          </div>
          <el-form-item prop="username">
            <input v-model="loginForm.username" type="text" autofocus auto-complete="off" placeholder="请填写您的系统账号" />
          </el-form-item>
          <div class="login-form-title h c fc-g">
            <i class="fa fa-key fs-large"></i>&nbsp;密码
          </div>
          <el-form-item prop="password">
            <input v-model="loginForm.password" type="password" auto-complete="off" placeholder="请填写您的登录密码" />
          </el-form-item>
          <div class="login-form-title h c fc-g">
            <i class="fa fa-shield-alt fs-large"></i>&nbsp;验证码
          </div>
          <el-form-item prop="code">
            <div class="h">
              <input v-model="loginForm.code" auto-complete="off" placeholder="请填写右侧的验证码" maxlength="4" class="flex" />
              <img :src="codeUrl" @click="getCode" style="margin-left: 10px; border-radius: 4px;" />
            </div>
          </el-form-item>

          <div class="h c sb sa-s" style="padding-top: 10px;">
            <button @click.prevent="handleLogin">
              <div class="h c" v-if="loading">
                <span>正在登录&emsp;</span>
                <i class="fs-large el-icon-loading"></i>
              </div>
              <div class="h c" v-else>
                <span>登录&emsp;</span>
                <i class="fs-large el-icon-right"></i>
              </div>
            </button>
            <el-checkbox v-model="loginForm.rememberMe">记住我的登录状态</el-checkbox>
          </div>
        </el-form>
      </div>
    </div>
    <!--  底部  -->
    <div class="login-footer">
      <span v-html="$store.state.settings.footerTxt" />
      <span>&nbsp;⋅&nbsp;</span>
      <a href="https://beian.miit.gov.cn" target="_blank">{{ $store.state.settings.caseNumber }}</a>
      <span>&nbsp;⋅&nbsp;</span>
      <a href="https://www.freepik.com/free-photos-vectors/business">Business vector created by fullvector - www.freepik.com</a>
    </div>
  </div>
</template>

<script>
import { encrypt } from "@/utils/rsaEncrypt";
import Config from "@/config";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      cookiePass: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [{ required: true, message: "用户名不能为空" }],
        password: [{ required: true, message: "密码不能为空" }],
        code: [{ required: true, message: "验证码不能为空" }],
      },
      loading: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      let password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      // 保存cookie里面的加密后的密码
      this.cookiePass = password === undefined ? "" : password;
      password = password === undefined ? this.loginForm.password : password;
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        code: "",
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        const user = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          rememberMe: this.loginForm.rememberMe,
          code: this.loginForm.code,
          uuid: this.loginForm.uuid,
        };
        if (user.password !== this.cookiePass) {
          user.password = encrypt(user.password);
        }
        if (valid) {
          this.loading = true;
          if (user.rememberMe) {
            Cookies.set("username", user.username, {
              expires: Config.passCookieExpires,
            });
            Cookies.set("password", user.password, {
              expires: Config.passCookieExpires,
            });
            Cookies.set("rememberMe", user.rememberMe, {
              expires: Config.passCookieExpires,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          this.$store
            .dispatch("Login", user)
            .then(() => {
              this.loading = false;
              this.$router.push({ path: "/" });
            })
            .catch(() => {
              this.loading = false;
              this.getCode();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.login {
  background: linear-gradient(30deg, #19467e, #3a6cab);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  .logo {
    width: 25%;
    height: 100%;
    padding: 10px 20px;
    background-repeat: no-repeat;
    background-image: url("/static/img/logo-mark.png");
    background-position: top left;
    background-size: contain;
  }

  .login-main {
    width: 60%;
    height: 80%;
    background-color: white;
    border-radius: 0 20px 0 20px;
    overflow: hidden;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url("/static/img/login-background.jpg");
    background-size: contain;

    .login-form-title {
      background-color: white;
      padding-right: 8px;
      padding-left: 8px;
      margin-left: 8px;
      margin-top: 6px;
      margin-bottom: -9px;
      z-index: 10;
    }

    .el-form-item {
      align-self: stretch;
      margin-bottom: 24px;
      &__error {
        padding: 6px 12px;
        color: #f56c6c;
      }

      &.is-error input {
        border-color: #f56c6c;
      }
    }

    input {
      width: 100%;
      padding: 18px 10px 8px 12px;
      border-radius: 4px;
      border: #e2e2e2 solid 1px;
      outline: none;
      font-size: 14px;
      transition: all 0.3s;
      &::-webkit-input-placeholder {
        color: #c0c4cc;
      }
      &:focus {
        border-color: #409eff;
        color: #409eff;
      }
    }

    button {
      padding: 15px 30px;
      border-radius: 80px;
      border: none;
      background-color: #409eff;
      color: white;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .login-footer {
    position: fixed;
    left: 0;
    bottom: 20px;
    right: 0;
    text-align: center;
    color: white;
    font-size: 12px;
    opacity: 0.7;
  }
}
// .login {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   background: #707070;
//   background-image:url('/static/img/background.jpg');
//   background-size: cover;
// }
// .title {
//   margin: 0px auto 30px auto;
//   text-align: center;
//   color: #707070;
// }

// .login-form {
//   border-radius: 6px;
//   background: #ffffff;
//   width: 400px;
//   padding: 25px 25px 5px 25px;
//   .el-input {
//     height: 38px;
//     input {
//       height: 38px;
//     }
//   }
//   .input-icon{
//     height: 39px;width: 14px;margin-left: 2px;
//   }
// }
// .login-tip {
//   font-size: 13px;
//   text-align: center;
//   color: #bfbfbf;
// }
// .login-code {
//   width: 33%;
//   display: inline-block;
//   height: 38px;
//   float: right;
//   img{
//     cursor: pointer;
//     vertical-align:middle
//   }
// }
</style>
